$black: #000;
$white: #fff;
$black73: rgba(0, 0, 0, 0.73);

$color-link-blue: #354ff4;
$color-fg-purple: #690eab;
$color-fg-pink: #c40555;
$color-bg-grey: #f9f9f9;
$color-bg-blue: #f5f6fe;
$color-fg-grey: #4f4f4f;
$color-magenta: #a90178;
$color-eggplant: #5117bf;
$color-coal: #1b1b1b;
$color-pagination-border: #dfe3e8;
$color-pagination-disabled-bg-grey: #eee;
$color-pagination-disabled-text-grey: #959595;
$color-shuttle-grey: #64676a;
$color-silver: #c4c4c4;
$color-black-pearl: #1e1f20;
$color-baltic-sea: #393a3b;
$color-lavender-blue: #c2ccff;

$images-path: "/theme/images";

$gradient-tangerine: radial-gradient(50% 50% at 50% 50%,
        #e34645 0%,
        #d9334e 100%);
$gradient-watermelon: radial-gradient(50% 50% at 50% 50%,
        #d22e55 0%,
        #ca2a5e 100%);
$gradient-merlot: radial-gradient(50% 50% at 50% 50%, #ad1c7a 0%, #98208c 100%);
$gradient-grape: radial-gradient(50% 50% at 50% 50%,
        #98208d 0%,
        #98218e 0.01%,
        #7436b5 100%);
$gradient-blueberry: radial-gradient(50% 50% at 50% 50%,
        #6e2db5 0%,
        #5848d2 100%);
$gradient-foxtel: radial-gradient(106.7% 10729.72% at -4.71% 0%,
        #eb7531 0%,
        #cf0541 37.59%,
        #92078b 69.87%,
        $color-link-blue 100%);

$gradient-key-line: linear-gradient(270deg,
        $color-link-blue 11.47%,
        $color-eggplant 39.73%,
        $color-magenta 71.65%,
        #db363a 97.87%,
        #3241de 97.87%);

$gradient-nav-link: linear-gradient(270deg,
        $color-link-blue 0%,
        rgba(53, 79, 244, 0.88) 81.37%,
        rgba(53, 79, 244, 0) 101.05%);

$gradient-nav-link-submenu: linear-gradient(270deg,
        $white 0%,
        rgba(255, 255, 255, 0.88) 81.37%,
        rgba(255, 255, 255, 0) 101.05%);

$min-font-size: 16px;
$ease-transition: all 0.25s ease;

$bpSmall: "sm";
$bpMobile: "md";
$bpTablet: "lg";
$bpDesktop: "xl";
$bpFull: "xxl";

$bpCustomDesktop: 1200px;
$bpCustomDesktopWide: 1856px;
$bpCustomDesktopUltraWide: 2592px;
