.badge {
  list-style: none;
  margin-right: 0.5rem;
  padding: 0;

  &--link {
    @include ease;

    font-weight: normal;
    color: $color-link-blue;
    text-decoration: none;
    background-color: rgba(53, 79, 244, 0.1);
    padding: 0.5rem 0.75rem;
    line-height: 1;
    z-index: 3;
    position: relative;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-size: 0.6875rem;

    @include media-breakpoint-up($bpDesktop) {
      font-size: 0.875rem;
    }

    &:hover {
      background-color: rgba(53, 79, 244, 0.3);
      text-decoration: none;
    }

    .dark-bg & {
      color: $color-lavender-blue;
      background-color: rgba(53, 79, 244, 0.1);

      &:hover {
        background-color: rgba(53, 79, 244, 0.4);
      }
    }
  }
}
