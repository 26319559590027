.stretch-arrow {
  position: relative;

  &--neck {
    @extend %card-arrow-line;
    @include ease;

    margin-top: 0.4375rem;
    margin-bottom: 0.4375rem;
    width: 1.875rem;
  }

  &--head {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    &::before,
    &::after {
      @extend %card-arrow-line;

      content: "";
      position: absolute;
      width: 0.625rem;
      right: -1px;
    }

    &::before {
      top: 0.25rem;
      transform: rotate(45deg);
    }
    &::after {
      bottom: 0.25rem;
      transform: rotate(-45deg);
    }
  }

  a:hover & {
    &--neck {
      width: 4rem;
    }
  }
}
