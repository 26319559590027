.block--body {
  table {
    width: 100%;
  }

  figure {
    display: table;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    max-width: 100%;
  }

  &-small-image-tiles {
    &--images {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 1.5rem;
      row-gap: 1.5rem;

      @include media-breakpoint-up($bpMobile) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include media-breakpoint-up($bpDesktop) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    &--image--link {
      width: 100%;

      @include media-breakpoint-up($bpMobile) {
        width: initial;
      }

      img {
        max-width: 100%;
      }
    }
  }
}
