.block--brands {
  &--logo {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    width: 120px;
    flex: 0 0 120px;

    @include media-breakpoint-up($bpMobile) {
      width: 90px;
      flex: 0 0 90px;
    }

    @include media-breakpoint-up($bpTablet) {
      width: auto;
      flex: 1 1 auto;
    }

    &--link {
      $link: &;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      min-height: 3rem;
      display: flex;
      position: relative;

      @include media-breakpoint-up($bpDesktop) {
        min-height: 4rem;
      }

      &--image {
        @include ease;

        max-width: 100%;
      }

      &--default,
      &--hover {
        margin-top: auto;
        margin-bottom: auto;
      }

      &--hover {
        position: absolute;
        top: 0;
        bottom: 0;
      }

      &--default {
        opacity: 1;
      }

      &--hover {
        opacity: 0;
      }

      &:hover {
        #{$link}--default {
          opacity: 0;
        }

        #{$link}--hover {
          opacity: 1;
        }
      }
    }
  }
}
