html,
body {
  height: 100%;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: $min-font-size;
  line-height: 1.8;
  font-weight: 300;
  color: $color-coal;

  .dark-bg & {
    color: $white;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
  font-style: normal;
  font-weight: 200;
  color: $color-fg-grey;

  .dark-bg & {
    color: $white;
  }
}

.h1,
h1 {
  font-size: 1.938rem;
  margin-bottom: 0.58em;

  @include media-breakpoint-up($bpSmall) {
    font-size: 2.313rem;
    margin-bottom: 0.54em;
  }

  @include media-breakpoint-up($bpTablet) {
    font-size: 4.75rem;
    margin-bottom: 0.368em;
  }
}

.h2,
h2 {
  font-size: 1.625rem;
  margin-bottom: 0.615em;

  @include media-breakpoint-up($bpSmall) {
    font-size: 1.938rem;
    margin-bottom: 0.58em;
  }

  @include media-breakpoint-up($bpTablet) {
    font-size: 3.563rem;
    margin-bottom: 0.368em;
  }
}

.h3,
h3 {
  font-size: 1.5rem;
  margin-bottom: 0.54em;

  @include media-breakpoint-up($bpSmall) {
    font-size: 1.625rem;
    margin-bottom: 0.615em;
  }

  @include media-breakpoint-up($bpTablet) {
    font-size: 2.688rem;
  }
}

.h4,
h4 {
  font-weight: 300;
  font-size: 1.375rem;
  margin-bottom: 0.545em;

  @include media-breakpoint-up($bpTablet) {
    font-size: 2rem;
    margin-bottom: 0.437em;
  }
}

.h5,
h5 {
  color: $color-fg-purple;
  font-weight: 600;
  font-size: 1.125rem;
  margin-bottom: 0.666em;

  @include media-breakpoint-up($bpTablet) {
    font-size: 1.5rem;
    margin-bottom: 0.583em;
  }

  .dark-bg & {
    color: $white;
  }
}

.h6,
h6 {
  color: $color-link-blue;
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 0.75em;

  .dark-bg & {
    color: $white;
  }
}

.color-purple {
  color: $color-fg-purple;
}

p {
  color: $color-fg-grey;
  font-size: 1rem;
  margin-bottom: 0.888em;

  @include media-breakpoint-up($bpSmall) {
    font-size: 1.125rem;
    margin-bottom: 1em;
  }

  .dark-bg & {
    color: $white;
  }

  .large &,
  &.large {
    color: $color-coal;
    font-size: 1.25rem;
    font-weight: 200;
    line-height: 1.6em;

    @include media-breakpoint-up($bpTablet) {
      font-size: 2rem;
    }

    .dark-bg & {
      color: $white;
    }
  }

  .small &,
  &.small,
  small {
    font-weight: 300;
    color: $color-fg-purple;
    font-size: 1rem;

    @include media-breakpoint-up($bpTablet) {
      font-size: 1.125rem;
    }
  }

  .tiny &,
  &.tiny {
    font-weight: 300;
    color: $color-link-blue;
    font-size: 0.625rem;

    @include media-breakpoint-up($bpTablet) {
      font-size: 0.6875rem;
    }
  }

  &,
  &.large,
  &.small,
  &.tiny {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

b,
strong {
  font-weight: 600;
}

ul.no-style {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
  }
}

table {
  color: $color-fg-grey;
  font-size: 1.125rem;
  margin-bottom: 0.888em;

  @include media-breakpoint-up($bpTablet) {
    font-size: 1.5rem;
    margin-bottom: 1em;
  }

  .dark-bg & {
    color: $white;
  }
}

.main-content p a {
  text-decoration: underline;
}
