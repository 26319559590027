html {
  scroll-behavior: auto !important;
}

body {
  min-width: 320px;
  scroll-behavior: smooth;
  position: relative;
  overflow-x: hidden;

  &.scroll-reveals {
    $zease: cubic-bezier(0.165, 0.84, 0.44, 1);

    [data-scroll] {
      visibility: hidden;
      will-change: transform, opacity;
      transition: opacity 0.6s cubic-bezier(0.5, 0, 0, 1) 0s,
        transform 0.6s cubic-bezier(0.5, 0, 0, 1) 0s;
    }

    [data-scroll],
    [data-scroll="out"] {
      opacity: 0;
      // Move down.
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 10, 0, 1);
    }

    [data-scroll="out"] {
      visibility: visible;
    }

    [data-scroll="in"] {
      visibility: visible;
      opacity: 1;
      // Move up.
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
  }
}

.main-content {
  overflow-x: hidden;
}

/**
 * image will cover the full container.
 */
.image-full-cover {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: auto;
}

/* unvisited link */
a {
  color: $color-coal;
  text-decoration: none;

  .dark-bg & {
    color: $white;
  }
}

/* mouse over link */
a:hover {
  color: $color-link-blue;
  text-decoration: underline;
}

/* selected link */
a:active {
  color: $color-link-blue;
}

figcaption {
  color: $color-fg-grey;
  font-size: 0.75rem;
  margin-top: 1rem;

  @media (min-width: $bpCustomDesktop) {
    font-size: 1.125rem;
    margin-top: 1.25rem;
  }

  .dark-bg & {
    color: $white;
  }
}

.inline-margin-bottom {
  &-small {
    margin-bottom: 2rem !important;
  }

  &-medium {
    margin-bottom: 3rem !important;
  }

  &-large {
    margin-bottom: 4rem !important;
  }
}

.block-margin {
  &-top {
    margin-top: 4rem;

    @include media-breakpoint-up($bpTablet) {
      margin-top: 6rem;
    }
  }

  &-bottom {
    margin-bottom: 4rem;

    @include media-breakpoint-up($bpTablet) {
      margin-bottom: 6rem;
    }
  }

  &-top-bottom {
    margin-top: 4rem;
    margin-bottom: 4rem;

    @include media-breakpoint-up($bpTablet) {
      margin-top: 6rem;
      margin-bottom: 6rem;
    }
  }

  &-top-small {
    margin-top: 3rem;

    @include media-breakpoint-up($bpTablet) {
      margin-top: 2.5rem;
    }
  }

  &-bottom-small {
    margin-bottom: 3rem;

    @include media-breakpoint-up($bpTablet) {
      margin-bottom: 2.5rem;
    }
  }

  &-top-bottom-small {
    margin-top: 3rem;
    margin-bottom: 3rem;

    @include media-breakpoint-up($bpTablet) {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }
  }
}

.block-padding {
  &-top {
    padding-top: 4rem;

    @include media-breakpoint-up($bpTablet) {
      padding-top: 6rem;
    }
  }

  &-bottom {
    padding-bottom: 4rem;

    @include media-breakpoint-up($bpTablet) {
      padding-bottom: 6rem;
    }
  }

  &-top-bottom {
    padding-top: 4rem;
    padding-bottom: 4rem;

    @include media-breakpoint-up($bpTablet) {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }
  }

  &-top-small {
    padding-top: 3rem;

    @include media-breakpoint-up($bpTablet) {
      padding-top: 2.5rem;
    }
  }

  &-bottom-small {
    padding-bottom: 3rem;

    @include media-breakpoint-up($bpTablet) {
      padding-bottom: 2.5rem;
    }
  }

  &-top-bottom-small {
    padding-top: 3rem;
    padding-bottom: 3rem;

    @include media-breakpoint-up($bpTablet) {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
  }
}

.bottom-spacer {
  height: 4rem;

  @include media-breakpoint-up($bpTablet) {
    height: 6rem;
  }
}
