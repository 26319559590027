.btn,
a.btn {
  @extend .ease;
  padding: 14px 40px 13px;
  align-items: center;
  text-align: center;
  position: relative;
  border-radius: 0;
  text-decoration: none !important;

  &,
  p {
    font-size: calc(1.2625rem + 0.15vw);

    @include media-breakpoint-up($bpDesktop) {
        font-size: 1.375rem;
    }
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    pointer-events: none;
    border-style: solid;
    border-color: transparent;
  }

  // This is the outer border on active states.
  $borderActiveWidth: 4px;
  &::before {
    top: -$borderActiveWidth;
    left: -$borderActiveWidth;
    width: calc(100% + #{2 * $borderActiveWidth});
    height: calc(100% + #{2 * $borderActiveWidth});
    border-width: 4px;
  }

  // This is the inner border on some hover states.
  &::after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-width: 2px;
  }

  &:hover,
  &.hover {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    box-shadow: none;

    &::before {
      border-color: $color-link-blue;
    }

    &,
    &:active,
    &.active {
      box-shadow: none;
    }
  }

  &:active,
  &.active {
    &::before {
      border-color: $color-link-blue;
    }
  }

  &-primary {
    color: $white;
    border: none;
    background-color: $color-link-blue;

    p {
        color: $white;
    }

    &:hover,
    &.hover {
      border: none;
      background-color: $color-fg-purple;
    }

    &:focus,
    &.focus {
      &,
      &:active,
      &.active {
        box-shadow: none;
      }
    }
  }

  &-white {
    color: $color-link-blue;
    background-color: $white;

    p {
        color: $color-link-blue;
    }

    &:hover {
      color: $white;
      background-color: transparent;

      &::after {
        border-color: $white;
      }
    }

    &:active,
    &.active {
      color: $color-link-blue;
      background-color: $white;
    }
  }

  &-trans-white {
    color: $white;
    background-color: transparent;

    p {
        color: $white;
    }

    &::after {
      border-color: $white;
    }

    &:hover {
      color: $color-link-blue;
      background-color: $white;
    }

    &:active,
    &.active {
      color: $color-link-blue;
      background-color: $white;
    }
  }
}
