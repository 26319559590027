.prefooter {
  background-color: $color-bg-blue;
  padding: 4rem 0;

  @include media-breakpoint-up($bpTablet) {
    padding: 6rem 0;
  }

  &--column {
    &:first-child {
      margin-bottom: 1.5rem;

      @include media-breakpoint-up($bpTablet) {
        margin-bottom: 0;
      }
    }

    &--link {
      @include ease;
      background-color: transparent;
      padding: 1.5rem;

      @include media-breakpoint-up($bpTablet) {
        padding: 3rem;
      }

      &:hover {
        text-decoration: none;
        background-color: $white;
        box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.05);
      }
    }

    &--eyebrow {
      text-transform: uppercase;
      font-weight: 700;
      line-height: 1.7;
      color: $color-link-blue;
      margin-bottom: 0.5rem;
      font-size: 0.75rem;

      @include media-breakpoint-up($bpTablet) {
        font-size: 0.875rem;
      }
    }

    &--heading {
      font-weight: 400;
      margin-bottom: 3rem;
      line-height: 1.26;
      color: $color-link-blue;
      font-size: 1.43rem;

      @include media-breakpoint-up($bpTablet) {
        font-size: 2.375rem;
      }
    }

    &--cta--text {
      line-height: 1.5;
      color: $color-link-blue;
      margin-right: 1rem;
      font-size: 1rem;

      @include media-breakpoint-up($bpTablet) {
        font-size: 1.125rem;
        margin-bottom: 0.2rem;
      }
    }
  }
}
