.block--home-hero {
  height: 100vh;

  .block--hero--content {
    text-align: center;
    position: absolute;
    color: $white;
    bottom: 4rem;
    width: 100%;
    left: 0;

    @include media-breakpoint-up($bpDesktop) {
      margin-top: 0;
      max-width: none;
      transform: translate(0, calc(-50% + 56px));
      bottom: 6rem;
    }

    &-container {
      .container {
        height: 100%;
      }
    }
  }
}
