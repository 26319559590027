$container-padding-override: 0.75rem;

.container {
  padding-right: calc($container-padding-override * 2);
  padding-left: calc($container-padding-override * 2);
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  padding-right: $container-padding-override;
  padding-left: $container-padding-override;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.row {
  margin-right: -$container-padding-override;
  margin-left: -$container-padding-override;
}

%col-styles {
  position: relative;
  min-height: 1px;
  padding-right: $container-padding-override;
  padding-left: $container-padding-override;
}

@each $size, $breakpoints in $grid-breakpoints {
  @for $i from 1 through $grid-columns {
    .col-#{$size}-#{$i} {
      @extend %col-styles;
    }
  }
}

.container,
.container-fluid {
  @media (min-width: $bpMobile) {
    padding-right: calc($container-padding-override * 2);
    padding-left: calc($container-padding-override * 2);
  }
}

.container {
  @media (min-width: $bpCustomDesktop) {
    max-width: 100%;
    padding-right: 3rem;
    padding-left: 3rem;
  }

  @media (min-width: $bpCustomDesktopWide) {
    padding-right: 6rem;
    padding-left: 6rem;
  }

  @media (min-width: $bpCustomDesktopUltraWide) {
    max-width: 2400px;
    padding-right: calc($container-padding-override * 8);
    padding-left: calc($container-padding-override * 8);
  }
}

// override for just the article section
.section--article .entry .container {
  @media (min-width: $bpCustomDesktopUltraWide) {
    max-width: 1728px;
    padding-right: calc($container-padding-override * 5.3333333);
    padding-left: calc($container-padding-override * 5.3333333);
  }
}
