.search-filters {
  &--filters {
    margin-bottom: 1.5rem;

    @include media-breakpoint-up($bpDesktop) {
      margin-bottom: 3rem;
    }

    &--top {
      background-color: $white;
      border-bottom: 2px solid $white;
      justify-content: space-between;

      @include media-breakpoint-up($bpDesktop) {
        background-color: $color-bg-blue;
        padding: 0 1.5rem;
      }
    }

    &--bottom {
      background-color: $color-bg-blue;
    }

    &--search {
      flex: 0 0 100%;

      @include media-breakpoint-up($bpDesktop) {
        flex: 1 1 calc(100% - 19.2rem);
      }
    }

    &--clear {
      flex: 0 0 9.2rem;
      font-size: 0.875rem;
      line-height: 1.625rem;
      color: $color-link-blue;
      margin-right: 0;

      @include media-breakpoint-up($bpDesktop) {
        margin-right: 1rem;
      }

      button {
        @include ease;
        border: none;
        color: $color-link-blue;
        padding: 1.34rem 1.34rem 1.34rem 0;
        background-color: transparent;
        font-weight: 500;
        position: relative;

        @include media-breakpoint-up($bpDesktop) {
          padding: 1.34rem;
        }

        &.ais-ClearRefinements-button--disabled {
          opacity: 0;
          pointer-events: none;
        }

        &::before,
        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 2.1rem;
          height: 0.125rem;
          width: 0.75rem;
          background-color: $color-link-blue;
          pointer-events: none;
        }

        &::before {
          transform: rotate(45deg);
        }
        &::after {
          transform: rotate(-45deg);
        }

        &:hover {
          color: $color-fg-purple;

          &::before,
          &::after {
            background-color: $color-fg-purple;
          }
        }
      }
    }

    &--toggle {
      flex: 0 0 7.06rem;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.625rem;
      padding: 1.34rem 0.94rem 1.34rem 0;
      color: $color-fg-grey;
      position: relative;
      cursor: pointer;

      @include media-breakpoint-up($bpDesktop) {
        flex: 0 0 9rem;
        padding: 1.34rem 1.6rem 1.34rem 1.34rem;
      }

      &::before {
        @include ease;
        @extend %pseudoelement-filters-chevron;
        @extend %pseudoelement-chevron-down;
        pointer-events: none;
        position: absolute;
        right: 0.1rem;
        top: 1.8rem;

        @include media-breakpoint-up($bpDesktop) {
          right: 0.5rem;
        }
      }

      &:hover {
        color: $color-fg-purple;

        &::before {
          border-color: $color-fg-purple;
        }
      }

      &.open {
        &::before {
          top: 2.1rem;
          transform: rotate(135deg);
        }
      }
    }

    &--filter-wrapper {
      padding: 1.5rem 0.75rem 0.5rem;
      min-width: 100%;

      @include media-breakpoint-up($bpDesktop) {
        padding: 3rem;
        min-width: 23rem;
      }

      @include media-breakpoint-up($bpFull) {
        min-width: 27rem;
      }

      &--year {
        padding-bottom: 1.5rem;

        @include media-breakpoint-up($bpDesktop) {
          padding-bottom: 0;
        }
      }

      &--brand,
      &--year {
        flex: 0 0 100%;

        @include media-breakpoint-up($bpDesktop) {
          flex: 0 0 23rem;
        }

        @include media-breakpoint-up($bpFull) {
          flex: 0 0 27rem;
        }
      }

      &--topic {
        flex: 0 0 100%;

        @include media-breakpoint-up($bpDesktop) {
          flex: 1 1 calc(100% - 46rem);
        }

        @include media-breakpoint-up($bpFull) {
          flex: 1 1 calc(100% - 54rem);
        }
      }
    }

    &--heading {
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 0.875rem;
      color: $color-shuttle-grey;
      margin-bottom: 0.75rem;

      @include media-breakpoint-up($bpDesktop) {
        font-size: 1rem;
        line-height: 1rem;
        margin-bottom: 1.5rem;
      }
    }

    &--refinement {
      &--list {
        padding: 0;
        margin: 0;
      }

      &--item {
        list-style: none;
        margin: 0 0.5rem 0.5rem 0;
      }

      &--checkbox,
      &--count {
        display: none;
      }

      &--label-text {
        @include ease;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 0.875rem;
        color: $color-shuttle-grey;
        text-transform: uppercase;
        color: $color-fg-grey;
        border: 1px solid rgba(79, 79, 79, 0.3);
        box-sizing: border-box;
        border-radius: 0.875rem;
        padding: 0.25rem 0.75rem;
        display: block;
        cursor: pointer;

        @include media-breakpoint-up($bpDesktop) {
          font-size: 1rem;
          line-height: 1rem;
          border-radius: 1rem;
          padding: 0.4375rem 0.75rem;
        }
      }

      &--selected-item .label-text,
      &--label-text:hover {
        color: $white;
        background: $color-link-blue;
        border: 1px solid $color-link-blue;
        box-sizing: border-box;
      }
    }
  }
}
