.section--page .entry--livestream {
  padding-top: 9.375rem;

  @include media-breakpoint-up($bpDesktop) {
    padding-top: 12.5rem;
  }

  &--video--player {
    width: 100%;
  }

  .entry--livestream--header,
  .block--video,
  .block--body {
    .col-12 {
      width: 100% !important;

      @include media-breakpoint-up($bpDesktop) {
        width: 83.4% !important;
        margin-left: 8.33333333%;
      }
    }
  }
}
