.block--strategy-columns {
    background: rgba(53, 79, 244, 0.05);
    padding-top: 63px;
    padding-bottom: 11px;

    @include media-breakpoint-up($bpTablet) {
        padding-top: 97px;
        padding-bottom: 100px;
    }

    &--heading {
        padding-bottom: 61px;

        @include media-breakpoint-up($bpTablet) {
            padding-bottom: 94px;
        }
    }


    &--column {
        margin-bottom: 55px;

        &--image-wrapper {
            margin-bottom: 40px;
        }

        &--eyebrow {
            position: relative;
            margin-bottom: 29px;

            &,
            p {
                color: $black73;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 1.71;
                text-transform: uppercase;
            }

            &-line {
                height: 2px;
                width: 100%;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), rgba(0, 0, 0, 0.73);
            }
        }

        &--heading {
            margin-bottom: 7px;

            &,
            p {
                @extend .h3;
                @extend .color-purple;

                font-weight: 300;
                line-height: 1.2;
            }
        }

        &--heading-subtitle {
            margin-bottom: 31px;

            &,
            p {
                @extend .color-purple;

                font-weight: 300;
                line-height: 1.2;
                font-size: 1.25rem;

                @include media-breakpoint-up($bpTablet) {
                    font-size: 1.5rem;
                }
            }
        }
    }

}
