.breadcrumb {
  margin-bottom: 0;

  &-wrapper {
    position: absolute;
    top: 0;
  }

  &-item {
    & + & {
      padding-left: 1.2rem;
    }

    & + &::before,
    &::before {
      content: "";
      border-color: $white;
      float: none;
      padding-right: 0;
      color: white;
      display: inline-block;

      @extend %pseudoelement-chevron;
      @extend %pseudoelement-chevron-left;
    }

    &--link {
      @include animated-underline-hover;
      font-size: 1rem;
      margin-left: 0.625rem;
      line-height: 1.5;
      letter-spacing: 0.012em;
      color: $white;
      white-space: nowrap;

      &:hover {
        text-decoration: none;
        color: $white;
      }
    }
  }
}
