.pagination {
  $item: ".pagination--item";
  min-width: 20rem;

  &--link {
    width: 2rem;
    height: 2rem;
    opacity: 0.8;

    &:hover,
    &.hover {
      opacity: 1;
    }

    #{$item}--number &,
    #{$item}--first &,
    #{$item}--prev &,
    #{$item}--last &,
    #{$item}--next & {
      font-size: 0.875rem;
      font-weight: 600;
      border: 1px solid $color-pagination-border;
      margin: 0 0.5rem 0 0;
      outline: none;
      color: $color-coal;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;

      &:hover,
      &.hover,
      &:active,
      &.active {
        border-color: $color-link-blue;
        text-decoration: none;
        outline: none;
      }

      &:focus,
      &.focus {
        &,
        &:active,
        &.active {
          border: solid 2px $color-link-blue;
          outline: none;
        }
      }
    }

    #{$item}--number & {
      padding-top: 0.125rem;
    }

    #{$item}--current & {
      border: solid 2px $color-coal;
    }

    #{$item}--first &,
    #{$item}--prev &,
    #{$item}--last &,
    #{$item}--next & {
      position: relative;
      font-size: 0;

      &::after {
        @extend %pseudoelement-chevron;
      }
    }

    #{$item}--first &,
    #{$item}--last & {
      &::before {
        @extend %pseudoelement-chevron;
      }
    }

    #{$item}--first &,
    #{$item}--prev & {
      &::after {
        @extend %pseudoelement-chevron-left;
      }
    }

    #{$item}--first & {
      &::before {
        margin-left: 4px;
      }

      &::after,
      &::before {
        @extend %pseudoelement-chevron-left;
      }
    }

    #{$item}--next &,
    #{$item}--last & {
      &::after {
        @extend %pseudoelement-chevron-right;
      }
    }

    #{$item}--last & {
      &::before {
        margin-left: -4px;
      }

      &::after,
      &::before {
        @extend %pseudoelement-chevron-right;
      }
    }
  }
}
