.footer {
  background-color: $color-link-blue;
  color: $white;

  padding: 4rem 0 3rem;

  @include media-breakpoint-up($bpTablet) {
    padding: 6rem 0 3rem;
  }

  a {
    color: $white;
    text-decoration: none;
  }

  &--divider {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    margin-bottom: 3rem;
  }

  &--logo {
    @include ease;

    width: 11.25rem;
    height: auto;

    @include media-breakpoint-up($bpTablet) {
      width: 15rem;
      height: auto;
    }

    &--wrapper {
      @include ease;
      padding-bottom: 1.56rem;
    }
  }

  &--acknowledgement {
    line-height: 1.4em;
    font-size: 0.725rem;
    font-weight: 300;
    padding-bottom: 3.125rem;

    @include media-breakpoint-up($bpTablet) {
      font-size: 0.7875rem;
      max-width: 33rem;
    }

    @include media-breakpoint-up($bpDesktop) {
      padding-bottom: 1.56rem;
    }
  }

  &--column {
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    li {
      @include ease;

      margin-bottom: 1.25rem;
      padding: 0.25rem 0;

      @include media-breakpoint-up($bpDesktop) {
        margin-bottom: 0.3125rem;
      }
    }

    &,
    a {
      @include ease;

      font-weight: 300;
      font-size: 0.875rem;
      line-height: 1.4em;
    }

    a {
      @include animated-underline-hover;

      &::after {
        background: $white;
      }
    }

    strong {
      &,
      a {
        font-weight: 600;
      }
    }
  }

  &--section--columns {
    transition: padding 0.25s ease;

    padding-bottom: 4rem;

    @include media-breakpoint-up($bpDesktop) {
      padding-bottom: 6rem;
    }
  }

  &--section--copyright {
    line-height: 1.4em;
    font-size: 0.625rem;
    font-weight: 300;

    @include media-breakpoint-up($bpTablet) {
      font-size: 0.6875rem;
    }

    a {
      @include animated-underline-hover;

      &::after {
        background: $white;
      }
    }
  }

  &--copyright {
    width: 100%;

    @include media-breakpoint-up($bpMobile) {
      width: auto;
    }
  }
}
