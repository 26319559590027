.disclaimer {
  margin-bottom: 2rem;

  &,
  p {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.8;
  }
}
