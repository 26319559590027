/**
 * Note on usage: must have a position:relative parent. default is slant-right, align-top
 **/

.gradient-line {
  @include brand-line-gradient;

  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 2;

  &--slant-right {
    &::after {
      transform: rotate(5deg);
    }
  }

  &--slant-left {
    &::after {
      transform: rotate(-5deg);
    }
  }

  &--align-top {
    &::after {
      top: 40%;
      bottom: auto;
    }
  }

  &--align-bottom {
    &::after {
      bottom: 20%;
      top: auto;
    }
  }
}
