.block--feature {
  $blockClass: &;
  position: relative;
  margin-bottom: 8rem;

  @include media-breakpoint-up($bpTablet) {
    margin-bottom: 12rem;
  }

  &--image-wrapper {
    margin-top: 0;
    width: 100%;
    height: auto;
    bottom: -4rem;

    @include media-breakpoint-up($bpDesktop) {
      bottom: -6rem;
      width: calc(50vw - 0.75rem);
      height: 100%;
    }
  }

  &--image-on-left {
    #{$blockClass}--image-wrapper {
      float: right;
    }
  }

  &--column {
    z-index: 3;
    position: relative;

    &--content {
      padding-bottom: 2rem;

      @include media-breakpoint-up($bpDesktop) {
        padding-bottom: 6rem;
      }
    }
  }

  &--text-small {
    &.small,
    &.small:last-of-type {
      margin-top: 0.888rem;

      @include media-breakpoint-up($bpTablet) {
        margin-top: 1rem;
      }
    }
  }

  &--button {
    margin-top: 1.6em;

    @include media-breakpoint-up($bpTablet) {
      margin-top: 2em;
    }
  }

  .gradient-line {
    overflow: visible;

    &::after {
      bottom: 10%;
    }
  }
}
