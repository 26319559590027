.social-share {
  &--title {
    color: $color-shuttle-grey;
    font-size: 1.125rem;
    line-height: 1.8;
    margin-bottom: 0.5rem;
  }

  &--items {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &--item {
    margin-right: 1rem;
  }

  &--link {
    width: 2rem;
    height: 2rem;
    position: relative;
    font-size: 0;
    display: block;

    &::before,
    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: cover;
    }

    &::after {
      @include ease;
      opacity: 0;
    }

    &:hover,
    &:active {
      &::after {
        opacity: 1;
      }
    }

    &--facebook {
      &::before {
        background-image: url("#{$images-path}/share-facebook.svg");
      }

      &::after {
        background-image: url("#{$images-path}/share-facebook-hover.svg");
      }
    }

    &--twitter {
      &::before {
        background-image: url("#{$images-path}/share-twitter.svg");
      }

      &::after {
        background-image: url("#{$images-path}/share-twitter-hover.svg");
      }
    }

    &--linkedin {
      &::before {
        background-image: url("#{$images-path}/share-linkedin.svg");
      }

      &::after {
        background-image: url("#{$images-path}/share-linkedin-hover.svg");
      }
    }
  }
}
