.cards-modal {
  $image-width: 287px;
  $modal-y-padding: 5rem;
  $modal-x-padding-mobile: 2.5rem;
  $modal-x-padding-desktop: 4.5rem;

  .modal-dialog {
    max-width: none;
    margin: 0 auto;
    width: 100%;
    min-height: 100%;

    @include media-breakpoint-up($bpMobile) {
      max-width: 68rem;
      margin: 2rem auto;
      width: calc(100% - 2rem);
      min-height: calc(100% - 1rem);
    }

    @include media-breakpoint-up($bpDesktop) {
      margin: 1.75rem auto;
      width: 80%;
      min-height: calc(100% - 3.5rem);
    }

    @include media-breakpoint-up($bpFull) {
      width: 60%;
    }
  }

  &--content {
    position: relative;
    border-radius: 0;
  }

  &--header {
    border: none;
    padding: $modal-y-padding $modal-x-padding-mobile 0;

    @include media-breakpoint-up($bpDesktop) {
      padding: $modal-y-padding $modal-x-padding-desktop 0;
    }

    .close {
      margin: 0;
      padding: 0;
      opacity: 1;
    }
  }

  &--image {
    width: 100%;
    margin-bottom: 1.5rem;

    @include media-breakpoint-up($bpDesktop) {
      flex: 0 0 $image-width;
      width: $image-width;
      margin-right: 1.5rem;
      margin-bottom: 0;
    }

    @include media-breakpoint-up($bpFull) {
      margin-right: 4rem;
    }

    img {
      width: 100%;
      max-width: 300px;
    }
  }

  &--title-text {
    width: 100%;

    @include media-breakpoint-up($bpDesktop) {
      flex: 0 0 calc(100% - 19.43rem);
      margin-bottom: 0;
    }

    @include media-breakpoint-up($bpFull) {
      flex: 0 0 calc(100% - 21.93rem);
    }
  }

  &--title {
    font-size: 1.75rem;
    margin-bottom: 0;
  }

  &--text.small {
    font-size: 0.75rem;
    color: $color-coal;
  }

  &--close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 1.5rem;
    height: 1.5rem;
    outline: none;
    border: none;
    background-color: transparent;

    &:active,
    &.active {
      transform: scale(0.9);
    }

    &:focus,
    &.focus {
      outline: none;
    }

    span {
      display: none;
    }

    &::before,
    &::after {
      transition: background-color 0.25s ease;
      content: "";
      position: absolute;
      width: 1.375rem;
      height: 0.1875rem;
      background-color: $color-coal;
      top: 0.75rem;
      right: 0;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

    &:hover,
    &.hover {
      &::before,
      &::after {
        background-color: $color-link-blue;
      }
    }

    &:focus,
    &.focus {
      &::before,
      &::after {
        background-color: $color-fg-purple;
      }
    }
  }

  &--body {
    padding: 0 $modal-x-padding-mobile $modal-y-padding;

    @include media-breakpoint-up($bpDesktop) {
      padding: 0 $modal-x-padding-desktop $modal-y-padding;
    }

    &,
    p {
      font-size: 1rem;
    }
  }
}
