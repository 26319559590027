.bg-transparent {
  background-color: transparent;
}

.bg-light-grey {
  background-color: $color-bg-grey;
}

.bg-light-blue {
  background-color: $color-bg-blue;
}

%card-arrow-line {
  height: 0.125rem;
  background-color: $color-link-blue;
  border-radius: 0.125rem;

  .dark-bg & {
    background-color: $white;
  }
}
