.video {
  iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &--embed-code {
    &--wrapper {
      position: relative;
      display: block;
    }
    &--centerer {
      padding-top: 56.25%;
    }
  }
}
