.header {
  z-index: 10;

  &--type {
    &--color {
      &,
      a {
        color: $white;
      }

      .hamburger span {
        background-color: $white;
      }
    }

    &--white {
      &,
      a {
        color: $black;
      }

      .hamburger span {
        background-color: $black;
      }
    }
  }

  &--logo {
    margin-left: -48px;
    width: 262px;
    height: 52px;

    @include media-breakpoint-up($bpTablet) {
      margin-left: initial;
    }

    @include media-breakpoint-up($bpDesktop) {
      width: 382px;
      height: 75px;
    }

    &--wrapper {
      z-index: 25;
    }
  }
}
