.block--hero {
  color: $white;
  height: 350px;
  min-height: 350px;
  overflow: hidden;

  @include media-breakpoint-up($bpTablet) {
    height: 593px;
    min-height: 593px;
  }

  picture {
    width: 100%;
    height: 100%;
    display: flex;
  }

  &--content-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    .container {
      padding-top: 2rem;
      padding-bottom: 2rem;
      height: calc(100% - 84px);

      @include media-breakpoint-up($bpTablet) {
        height: calc(100% - 148px);
      }

      @include media-breakpoint-up($bpDesktop) {
        height: calc(100% - 171px);
      }
    }
  }

  &--content {
    text-align: left;

    @include media-breakpoint-up($bpDesktop) {
      max-width: 800px;
    }
  }

  &--title-text {
    color: $white;
  }

  &--text {
    &,
    &.small {
      color: $white;
    }
  }
}
