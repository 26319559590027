.four-up {
  list-style: none;

  img,
  video {
    max-width: 100%;
    display: block;
    width: 100%;
    height: auto;
  }

  &--card {
    $cardClass: &;
    @include ease;
    margin-bottom: 1.5rem;

    @include media-breakpoint-up($bpTablet) {
      margin-bottom: 3rem;
    }

    &--top {
      margin-bottom: 0.5rem;
    }

    &--link {
      &::after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 2;
      }

      &,
      &:hover {
        text-decoration: none;
      }
    }

    &--badges {
      margin: 0;
      padding: 1rem 1rem 0;
    }

    &--content {
      padding: 1rem 1rem 3.5rem;
    }

    &--title,
    &--smallText {
      &,
      a {
        font-weight: 300;
        color: $color-link-blue;

        .dark-bg & {
          color: $white;
        }
      }
    }

    &--smallText {
      margin-bottom: 1.5rem !important;
    }

    &:hover {
      background-color: $white;
      box-shadow: 0.9rem 0.9rem 3.125rem 0 rgba(0, 0, 0, 0.05);

      .dark-bg & {
        background-color: rgba(255, 255, 255, 0.01);
      }

      .stretch-arrow--neck {
        width: 4rem;
      }
    }
  }
}
