.block--feature-brand {
  $blockClass: &;
  position: relative;
  margin-bottom: 4rem;

  @include media-breakpoint-up($bpDesktop) {
    margin-bottom: 6rem;
  }

  &--image-wrapper {
    margin-top: 0;
    width: 100%;
    height: auto;

    @media (min-width: $bpCustomDesktop) {
      width: calc(100% + 6rem);
      max-width: 48vw;
      height: 100%;
      display: flex;
    }
  }

  &--image-on-right {
    figcaption {
      text-align: right;
    }
  }

  &--image-on-left {
    #{$blockClass}--image-wrapper {
      float: right;
    }

    figcaption {
      text-align: left;
    }
  }

  &--column {
    z-index: 3;
    position: relative;

    &--content {
      padding-bottom: 2rem;

      @include media-breakpoint-up($bpDesktop) {
        padding-bottom: 4rem;
      }
    }
  }

  &--text {
    margin-bottom: 0.888em;

    @include media-breakpoint-up($bpTablet) {
      margin-bottom: 1em;
    }
  }

  .gradient-line--align-top::after {
    bottom: 20%;
    top: auto;

    @include media-breakpoint-up($bpDesktop) {
      top: 40%;
      bottom: auto;
    }
  }
}
