.section--article {
  .entry--article,
  .entry--video {
    padding-top: 9.375rem;

    @include media-breakpoint-up($bpDesktop) {
      padding-top: 12.5rem;
    }

    &--header {
      &--badges {
        margin: 0;
        padding: 0 0 1rem;
      }

      &--post-date {
        font-size: 0.75rem;
        line-height: 1.8;
        color: $color-fg-grey;
      }

      &--share {
        margin-top: 3rem;
      }
    }

    &--footer--share {
      border-top: 1px solid $color-silver;
      padding-top: 0.5rem;
    }
  }
}
