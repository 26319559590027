.section--page .entry--brand {
  $prefix: '.entry--brand';

  &--wrapper {
    @include ease;

    padding: 5.25rem 1.5rem 0;
    margin-bottom: 1.5rem;

    @include media-breakpoint-up($bpTablet) {
      padding: 9.25rem 3rem 0;
      margin-bottom: 3rem;
    }

    @media (min-width: $bpCustomDesktop) {
      padding-top: 10.6875rem;
    }
  }

  &--content-wrapper {
    @include ease;

    padding: 2rem 1rem 3rem;

    @media (min-width: $bpCustomDesktop) {
      padding: 6rem;
    }

    .container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  &--overview-text {
    font-weight: 300;
    font-size: 1.375rem;
    line-height: 1.4;
    margin-bottom: 1.5rem;

    @include media-breakpoint-up($bpTablet) {
      padding-right: 0.3rem;
    }

    @media (min-width: $bpCustomDesktop) {
      margin-bottom: 3rem;
      font-size: 2rem;
      padding-right: 0.5rem;
    }
  }

  &--links {
    margin-bottom: 1.5rem;

    @include media-breakpoint-up($bpTablet) {
      margin-bottom: 0;
    }

    &--items {
      padding: 0;
      margin: 0;
    }

    &--item {
      list-style: none;
      margin-bottom: 0.7rem;

      @media (min-width: $bpCustomDesktop) {
        margin-bottom: 1rem;
      }
    }

    &--link {
      font-weight: 300;
      font-size: 0.875rem;
      line-height: 1.4;
      text-decoration: underline;

      @media (min-width: $bpCustomDesktop) {
        font-size: 1.125rem;
      }

      &:hover {
        color: $color-link-blue;
      }
    }
  }

  &--body {
    &,
    p {
      font-weight: 300;
      font-size: 0.875rem;
      line-height: 1.78;

      @media (min-width: $bpCustomDesktop) {
        font-size: 1.125rem;
        line-height: 2;
      }
    }
  }

  &--column-right {
    @include media-breakpoint-up($bpTablet) {
      border-left: 3px solid $color-baltic-sea;
      padding-left: 2rem;
    }

    @media (min-width: $bpCustomDesktop) {
      padding-left: 3rem;
    }
  }

  &--latest-articles {
    &--heading {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.4;
      margin-bottom: 1.5rem;

      @media (min-width: $bpCustomDesktop) {
        font-size: 1.5rem;
        margin-bottom: 3rem;
      }
    }

    &--view-all {
      width: 100%;
      padding: 1.25rem 1.5rem;
      margin-bottom: 1.5rem;

      @media (min-width: $bpCustomDesktop) {
        margin-bottom: 3rem;
      }

      &--link {
        font-size: 1rem;
        line-height: 1.8;
      }
    }
  }

  &--highlights {
    &--column {
      &--image {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 1.5rem;

        @media (min-width: $bpCustomDesktop) {
          padding-left: 0.75rem;
          margin-bottom: 0;
        }
      }

      &--content {
        padding-bottom: 1rem;

        @media (min-width: $bpCustomDesktop) {
          padding-bottom: 1.5rem;
          padding-right: 0.75rem;
        }
      }
    }

    &--heading {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.4;
      margin-bottom: 1rem;

      @media (min-width: $bpCustomDesktop) {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
      }
    }

    &--title {
      font-weight: 300;
      font-size: 1.375rem;
      line-height: 1.4;
      margin-bottom: 1.5rem;

      @media (min-width: $bpCustomDesktop) {
        font-size: 2rem;
        margin-bottom: 3rem;
      }
    }

    &--download {
      font-size: 1rem;
      text-decoration: none;

      @media (min-width: $bpCustomDesktop) {
        font-size: 1.375rem;
      }
    }
  }



  &--content-wrapper {
    &.dark-bg {
      background-color: $color-black-pearl;
      color: $white;

      #{$prefix}--latest-articles {
        &--heading {
          color: $white;
        }

        &--view-all {
          background-color: rgba(246, 246, 250, 0.15);

          &--link {
            color: $white;
          }
        }
      }

      #{$prefix}--highlights {
        &--heading {
          color: $white;
        }

        &--title {
          color: $white;
        }
      }
    }

    &.light-bg {
      background-color: $white;
      color: $color-fg-grey;

      #{$prefix}--latest-articles {
        &--heading {
          color: $color-fg-grey;
        }

        &--view-all {
          background-color: $color-bg-blue;

          &--link {
            color: $color-fg-grey;
          }
        }
      }

      #{$prefix}--highlights {
        &--heading {
          color: $color-fg-grey;
        }

        &--title {
          color: $color-fg-grey;
        }
      }
    }
  }
}
