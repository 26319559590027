.block--spacer {
  &--short {
    height: 2rem;

    @include media-breakpoint-up($bpDesktop) {
      height: 3rem;
    }
  }

  &--normal {
    height: 4rem;

    @include media-breakpoint-up($bpDesktop) {
      height: 6rem;
    }
  }

  &--tall {
    height: 6rem;

    @include media-breakpoint-up($bpDesktop) {
      height: 9rem;
    }
  }
}
