.navigation {
  .navigation--link {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.2;
    align-items: center;
    text-decoration: none;
    padding-bottom: 0.3125rem;

    @include animated-underline-hover;

    &::after {
      bottom: 0.5rem;
      background: $gradient-nav-link;
    }

    &.active::after {
      width: 100%;
    }
  }

  &--list {
    &--sub {
      // Disable nav deeper than 2 levels.
      .navigation--list--sub {
        opacity: 0 !important;
        display: none !important;
      }
    }
  }

  &--desktop {
    .navigation {
      &--link {
        margin: 0.5rem;
        padding-bottom: 0.8125rem;
      }

      &--list--sub {
        @include ease;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        padding: 0;
        position: absolute;
        top: calc(100% + 0.5rem);
        left: 0.5rem;
        width: 21.56rem;
        background-color: $color-link-blue;

        .navigation--item {
          margin-left: 0;
          list-style: none;

          &:last-child {
          }
        }

        .navigation--link {
          margin: 0;
          display: block;
          color: $white;
          padding: 1rem 1.5rem;

          &::after {
            display: none;
          }

          &:hover,
          &.active {
            background-color: rgba(0, 0, 0, 0.2);
          }
        }
      }

      &--item {
        position: relative;
        margin-left: 0.5rem;

        &:hover .navigation--list--sub,
        .navigation--list--sub:hover {
          visibility: visible;
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }
}

.hamburger {
  display: block;
  position: relative;
  top: 0;
  z-index: 100;
  width: 32px;
  height: 32px;
  margin: 0 8px 0 auto;
  cursor: pointer;
  padding: 1rem;

  span {
    @include ease;

    // Hamburger bars
    display: block;
    position: absolute;
    width: 24px;
    top: 16px;
    left: 4px;
    height: 1px;
    border-radius: 1.5px;
    transform: rotate(0deg);
    opacity: 0.7;
    box-shadow: 1px 1px 1px 0px $black;

    // Bar positions
    &:nth-child(1) {
      top: 10px;
    }

    &:nth-child(2) {
      top: 22px;
    }
  }

  &:hover {
    span {
      opacity: 1;
    }
  }

  &.clicked {
    span {
      background-color: $black;
      box-shadow: none;

      &:nth-child(1),
      &:nth-child(2) {
        top: 14px;
      }

      &:nth-child(1) {
        transform: rotate(-45deg);
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        opacity: 0;
      }
    }
  }
}

.hamburger-menu {
  @include ease;
  background-color: $white;
  opacity: 0; // to ensure smooth transition
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 600px;
  padding-top: 84px;
  pointer-events: none;

  .scrollbox {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }

  &.active {
    opacity: 1 !important;
    pointer-events: auto;
  }

  .navigation {
    &--item {
      position: relative;

      &:first-child {
        margin-left: 0;
      }
    }

    &--link {
      font-size: 1.125rem;
      font-weight: 600;
      color: $color-fg-grey;
      line-height: 1;
      position: relative;
      padding-top: 0.5rem;
      padding-bottom: 0.8125rem;
      text-decoration: none;
      margin-bottom: 0.6975rem;
      margin-left: 1.5rem;
    }

    &--list {
      width: 100%;
      margin: 0;
      margin-top: 6.25rem;
      max-height: 100%;
    }

    &--list--sub {
      padding-bottom: 1.5rem;
      padding-left: 1.5rem;

      // Disable nav deeper than 2 levels.
      .navigation--list--sub {
        opacity: 0 !important;
        display: none !important;
      }
    }

    // Toggle
    &--toggle {
      position: absolute;
      top: 0;
      right: 1rem;
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &--chevron,
      &.open .navigation--toggle--chevron {
        @include ease;
        @extend %pseudoelement-menu-chevron;
        @extend %pseudoelement-chevron-up;
        width: 0.5rem;
        height: 0.5rem;
      }

      &.open .navigation--toggle--chevron {
        transform: rotate(135deg);
      }
    }
  }
}
