.section--article .entry--article--image {
  position: relative;
  z-index: 3;
  width: 100%;

  &-container {
    picture {
      @include media-breakpoint-up($bpFull) {
        width: calc(100% + 6rem);
        margin-left: -6rem;
      }
    }
  }
}
